import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const homeEndpoint = process.env.REACT_APP_HOME_ENDPOINT;

export const aboutEndpoint = process.env.REACT_APP_ABOUT_ENDPOINT;

export const vaccineEndpoint = process.env.REACT_APP_VACCINE_ENDPOINT;

export const newsEndpoint = process.env.REACT_APP_NEWS_ENDPOINT;
