import "./Footer.css";
import { Row } from "react-bootstrap";

function Footer() {
  return (
    <Row className="footer-row">
      <p className="copyright-text">© Todos os direitos reservados | 2021</p>
    </Row>
  );
}

export default Footer;
