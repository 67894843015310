import { useEffect, useState } from "react";
import { Accordion, Col, Row, Spinner } from "react-bootstrap";
import { api, vaccineEndpoint } from "../../axios";
import PagesBanner from "../../components/PagesBanner/PagesBanner";
import childrenHoverIcon from '../../images/children-hover.svg';
import childrenIcon from '../../images/children.svg';
import eldersHoverIcon from '../../images/elders-hover.svg';
import eldersIcon from '../../images/elders.svg';
import planeHoverIcon from '../../images/plane-hover.svg';
import planeIcon from '../../images/plane.svg';
import teenagersHoverIcon from '../../images/teenagers-hover.svg';
import teenagersIcon from '../../images/teenagers.svg';
import womenHoverIcon from '../../images/women-hover.svg';
import womenIcon from '../../images/women.svg';
import "./Vaccines.css";

function Vaccines() {
  const title = "LISTA DE VACINAS";
  const [data, setData] = useState(null);
  const [vaccines, setVaccines] = useState(null);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    api
      .get(vaccineEndpoint)
      .then((data) => {
        setData(data?.data?.objects);
        setFilter("children");
      })
      .catch(() => setData(null));
  }, []);

  useEffect(() => {
    setVaccines(null);
    setVaccines(data?.filter(vaccine => vaccine.metadata[filter] === true));
  }, [filter, data]);

  const renderFilter = (title, subtitle, img, imgHover, id) => {
    return (
      <Col className={`vaccine-filter ${filter === id && 'vaccine-filter-selected'}`}
        onMouseOver={() => filter !== id && (document.getElementById(`${id}-icon`).src = imgHover)}
        onMouseOut={() => filter !== id && (document.getElementById(`${id}-icon`).src = img)}
        onClick={() => { setFilter(id) }}
      >
        <img
          className="vaccine-filter-icon"
          src={filter === id ? imgHover : img}
          id={`${id}-icon`}
          alt={`Ícone de ${title}`}
        />
        <div className="vaccine-filter-text">
          <h2 className="vaccine-filter-title">{title}</h2>
          {subtitle && <h3 className="vaccine-filter-subtitle">({subtitle})</h3>}
        </div>
      </Col>
    );
  }

  return vaccines !== null && vaccines !== undefined ? (
    <div className="view-container">
      <PagesBanner title={title} />
      <Row className="vaccine-filters">
        {renderFilter("Crianças", "0 - 10 anos", childrenIcon, childrenHoverIcon, "children")}
        {renderFilter("Adolescentes", "11 - 19 anos", teenagersIcon, teenagersHoverIcon, "teens")}
        {renderFilter("Mulheres", "20 - 59 anos", womenIcon, womenHoverIcon, "women")}
        {renderFilter("Idosos", "+60 anos", eldersIcon, eldersHoverIcon, "elders")}
        {renderFilter("Viajantes", null, planeIcon, planeHoverIcon, "travellers")}
      </Row>
      <div className="vaccines-content">
        <Accordion>
          {vaccines?.map((vaccine, index) => {
            return <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{vaccine.title}</Accordion.Header>
              <Accordion.Body>
                {vaccine.metadata.description}
              </Accordion.Body>
            </Accordion.Item>
          })}
        </Accordion>
      </div>
    </div>
  ) : <Spinner animation="border" className="global-spinner" />;
}

export default Vaccines;
