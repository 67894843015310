import "./PagesBanner.css";
import { Row, Container } from "react-bootstrap";

function PagesBanner(props) {
  return (
    <Row className="pages-banner">
      <Container fluid className="banner-content">
        <h1>{props.title}</h1>
        <hr className="banner-line" />
      </Container>
    </Row>
  );
}

export default PagesBanner;
