import { Container, Row, Spinner, Carousel } from "react-bootstrap";
import { useEffect, useState } from "react";
import { api, homeEndpoint } from "../../axios";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import FeedbackCarousel from "../../components/FeedbackCarousel/FeedbackCarousel";
import GalleryCarousel from "../../components/GalleryCarousel/GalleryCarousel";
import ImgTextRow from "../../components/ImgTextRow/ImgTextRow";
import "./Home.css";

function Home() {
  const [data, setData] = useState(null);

  useEffect(() => {
    api
      .get(homeEndpoint)
      .then((data) => {
        setData(data?.data?.object?.metadata);
      })
      .catch(() => setData(null));
  }, []);

  const slider = (items, scheduleLink) => {
    return (
      <Carousel>
        {items.map((item) => {
          return (
            <Carousel.Item>
              <Row
                className="home-banner"
                style={{ backgroundImage: `url(${item.image.url})` }}
              >
                <Container className="banner-container-bg">
                  <Container className="banner-container"></Container>
                </Container>
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };
  return data !== null && data !== undefined ? (
    <Container fluid className="main-container">
      {slider(data.main_slide, data.schedule_link)}
      <ImgTextRow
        title={data.about_title}
        text={data.about_text}
        image={data.about_img.url}
      />
      <GalleryCarousel images={data.carousel_img} />
      <ImgTextRow
        title={data.mascot_title}
        text={data.mascot_text}
        image={data.mascot_img.url}
        invert={true}
      />
      <FeedbackCarousel feedbacks={data.feedbacks} />
      <ContactInfo
        info={data.contact_info}
        igLink={data.ig_link}
        scheduleLink={data.schedule_link}
        hasTitle={true}
      />
    </Container>
  ) : (
    <Spinner animation="border" className="global-spinner" />
  );
}

export default Home;
