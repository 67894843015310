import "./ImgTextRow.css";
import { Row, Col, Container } from "react-bootstrap";

function ImgTextRow(props) {
  return (
    <Row className={`row-content row-content-padding ${props.invert && "row-content-reverse"}`}>
      <Col xs={12} lg={{ span: 5, offset: props.invert ? 1 : 0 }}>
        <Container className={`title-container ${!props.title && "hide"}`}>
          <h2 className="row-content-title">{props.title}</h2>
          <hr className="row-content-line" />
        </Container>
        <p className="row-content-text">{props.text}</p>
      </Col>
      <Col xs={12} lg={{ span: 6, offset: props.invert ? 0 : 1 }} className="row-content-img" style={{ backgroundImage: `url(${props.image})` }}>
      </Col>
    </Row>
  );
}

export default ImgTextRow;
