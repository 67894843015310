import logo from "../../images/logo.svg";
import phoneIcon from "../../images/phone-icon.svg";
import igIcon from "../../images/ig-icon.svg";
import { NavLink, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { api, homeEndpoint } from "../../axios";
import { useEffect, useState } from "react";
import "./Menu.css";

function MainMenu() {
  const history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    api
      .get(homeEndpoint)
      .then((data) => {
        setData(data?.data?.object?.metadata);
      })
      .catch(() => setData(null));
  }, []);

  const navLinks = (
    <Nav className="me-auto nav-links">
      <NavLink exact activeClassName="link-active" to="/">
        INÍCIO
      </NavLink>
      <NavLink activeClassName="link-active" to="/sobre">
        SOBRE
      </NavLink>
      <NavLink activeClassName="link-active" to="/vacinas">
        VACINAS
      </NavLink>
      <NavLink activeClassName="link-active" to="/noticias">
        NOTÍCIAS
      </NavLink>
      <NavLink activeClassName="link-active" to="/contato">
        CONTATO
      </NavLink>
    </Nav>
  );

  const contactSection = (
    <Row xs="auto" className="contact-section">
      <Col className="phone-section">
        <img
          src={phoneIcon}
          alt="Icone de um telefone"
          width="18"
          className="phone-icon"
        />
        <Navbar.Text>(81) 3071-6819</Navbar.Text>
      </Col>
      <Col className="insta-icon">
        {data !== undefined && data !== null && (
          <a href={data.ig_link} target="_blank" rel="noreferrer">
            <img src={igIcon} alt="Icone do Instagram" width="30" />
          </a>
        )}
      </Col>
      <Col>
        {data !== undefined && data !== null && (
          <Nav.Link
            className="dark-btn"
            href={data.schedule_link}
            target="_blank"
            rel="noreferrer"
          >
            Agende agora
          </Nav.Link>
        )}
      </Col>
    </Row>
  );

  return (
    <Navbar collapseOnSelect={true} expand="lg">
      <Container className="menu-bar nav-size">
        <Navbar.Brand onClick={() => history.push("/")}>
          <img
            src={logo}
            className="prime-logo d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="menu-bar links-size"
        >
          <Row className="nav-content">
            <Col xs={12} md="auto">
              {navLinks}
            </Col>
            <Col xs={12} md="auto">
              {contactSection}
            </Col>
          </Row>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainMenu;
