import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import icon404 from '../../images/404.svg';
import './PageNotFound.css';

function PageNotFound() {
    return (
        <div className="view-container">
            <Container className="not-found-container">
                <img src={icon404} className="not-found-icon" alt="Ícone de 404" />
                <h1 className="not-found-title">Ops!</h1>
                <p className="not-found-text">A página que você está procurando não existe. Tente novamente ou volte para a <Link to="/">página inicial</Link>.</p>
            </Container>
        </div>
    );
}

export default PageNotFound;
