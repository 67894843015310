import { Container } from "react-bootstrap";
import PagesBanner from "../../components/PagesBanner/PagesBanner";
import './NewsPage.css';

function NewsPage(props) {
  const pageTitle = "NOTÍCIAS";
  const { title, text, image, date } = props;

  return (
    <div className="view-container">
      <PagesBanner title={pageTitle} />
      <Container className="news-page-container">
        {image && <img src={image} alt="Imagem da notícia" className="news-page-img" />}
        <h1 className="news-page-title">{title}</h1>
        <p className="news-page-date">{date}</p>
        <p className="news-page-text">{text}</p>
      </Container>
    </div>
  );
}

export default NewsPage;
