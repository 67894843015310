import PagesBanner from "../../components/PagesBanner/PagesBanner";
import { useEffect, useState } from "react";
import { api, homeEndpoint } from "../../axios";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import { Spinner } from "react-bootstrap";

function Contact() {
  const title = "CONTATO";
  const [data, setData] = useState(null);

  useEffect(() => {
    api
      .get(homeEndpoint)
      .then((data) => {
        setData(data?.data?.object?.metadata);
      })
      .catch(() => setData(null));
  }, []);

  return data !== null && data !== undefined ? (
    <div className="view-container">
      <PagesBanner title={title} />
      <ContactInfo
        info={data.contact_info}
        igLink={data.ig_link}
        scheduleLink={data.schedule_link}
        hasTitle={false}
        description={data.contact_descrip}
      />
    </div>
  ) : (
    <Spinner animation="border" className="global-spinner" />
  );
}

export default Contact;
