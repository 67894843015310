import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import MainMenu from "./components/Menu/Menu";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import About from "./views/About/About";
import Vaccines from "./views/Vaccines/Vaccines";
import News from "./views/News/News";
import Contact from "./views/Contact/Contact";
import PageNotFound from "./views/PageNotFound/PageNotFound";
import NewsPage from "./views/NewsPage/NewsPage";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <MainMenu></MainMenu>
        <Switch className="router-switch">
          <Route path="/sobre" component={About} />
          <Route path="/vacinas" component={Vaccines} />
          <Route exact path="/noticias" component={News} />
          <Route exact path="/noticias/:id" render={(e) => {
            const { title, text, image, date } = e.location.state;
            if (e.history.action === "PUSH") return <NewsPage title={title} text={text} image={image} date={date} />
            else {
              e.history.push('/noticias');
            }
          }} />
          <Route path="/contato" component={Contact} />
          <Route exact path="/" component={Home} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
