import "./GalleryCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function GalleryCarousel(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Carousel
      arrows={false}
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      containerClass="gallery-carousel-container"
      itemClass="gallery-carousel-item"
    >
      {props.images.map((image, index) => {
        return (
          <img key={index} src={image.image.url} alt="Imagens da clinica" />
        );
      })}
    </Carousel>
  );
}

export default GalleryCarousel;
