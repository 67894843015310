import "./About.css";
import ImgTextRow from "../../components/ImgTextRow/ImgTextRow";
import PagesBanner from "../../components/PagesBanner/PagesBanner";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { api, aboutEndpoint } from "../../axios";

function About() {
  const title = "SOBRE A PRIME VACCINE";
  const [data, setData] = useState(null);

  useEffect(() => {
    api
      .get(aboutEndpoint)
      .then((data) => {
        setData(data?.data?.object?.metadata);
      })
      .catch(() => setData(null));
  }, []);

  return data !== null && data !== undefined ? (
    <div className="view-container">
      <PagesBanner title={title} />
      <ImgTextRow title={false} text={data.text} image={data.main_img.url} />
      <Row className="images-row row-content">
        {
          data.images.map((item, index) => {
            return (
              <Col xs={12} sm={12} md={4} key={index}>
                <Container className="about-container" style={{ backgroundImage: `url(${item.image.url})` }}>
                </Container>
              </Col>
            )
          })
        }
      </Row>
    </div>
  ) : <Spinner animation="border" className="global-spinner" />;
}

export default About;
