import { Col, Container, Row } from "react-bootstrap";
import clockIcon from "../../images/clock-icon.svg";
import igIcon from "../../images/ig-icon.svg";
import locationIcon from "../../images/location-icon.svg";
import phoneIcon from "../../images/phone-icon.svg";
import "./ContactInfo.css";

function ContactInfo(props) {
  const infoLines = () => {
    return props.info.map((info, index) => {
      return (
        <Container className="contact-line" key={index}>
          <img
            alt="Ícone de contato"
            className="contact-icons"
            src={
              info.type === "phone"
                ? phoneIcon
                : info.type === "clock"
                  ? clockIcon
                  : locationIcon
            }
          />
          <p className="contact-line-text">{info.text}</p>
        </Container>
      );
    });
  };

  const map = (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15802.49965608392!2d-34.9231225!3d-8.037550899999998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab19bbe0c0cd11%3A0xe6994acd77d32a0d!2sPrime%20Vaccine!5e0!3m2!1spt-BR!2sbr!4v1627352418916!5m2!1spt-BR!2sbr"
      width="100%"
      height="100%"
      frameBorder="0"
      style={{ border: 0 }}
      loading="lazy"
      className="contact-row-map"
      title="Local da clínica"
    ></iframe>
  );

  const infoLinks = (<Container className="contact-line">
    <a href={props.igLink} target="_blank" rel="noreferrer">
      <img
        alt="Icone do instagram"
        className="contact-icons ig-icon"
        src={igIcon}
      />
    </a>
    <a
      className="dark-btn"
      href={props.scheduleLink}
      target="_blank"
      rel="noreferrer"
    >
      Agende agora
    </a>
  </Container>)

  const title = (
    <div>
      <h2 className="row-content-title">Contato</h2>
      <hr className="row-content-line" />
    </div>
  );

  return (
    <Container>
      {props.description && <Row className="row-content">
        <p className="contact-description">{props.description}</p>
      </Row>}
      <Row
        className={`row-content ${props.hasTitle ? "row-content-padding" : "row-content-start"
          }`}
      >
        <Col md="12" lg="3" className="contact-info">
          {props.hasTitle && title}
          {infoLines()}
          {infoLinks}
        </Col>
        <Col md="12" lg="9" className="contact-map-container">
          {map}
        </Col>
      </Row >
    </Container>
  );
}

export default ContactInfo;
