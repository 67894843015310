import { Container, Card, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { api, newsEndpoint } from "../../axios";
import PagesBanner from "../../components/PagesBanner/PagesBanner";
import './News.css';

function News() {
  const title = "NOTÍCIAS";
  const [data, setData] = useState(null);
  let history = useHistory();

  useEffect(() => {
    api
      .get(newsEndpoint)
      .then((data) => {
        setData(data?.data?.objects);
      })
      .catch(() => setData(null));
  }, []);

  const formatDate = date => {
    const [yy, mm, dd] = date.split(/-/g);
    return `${dd}/${mm}/${yy}`;
  };

  const goToNewsPage = (item, index) => {
    history.push({
      pathname: `/noticias/${index}`,
      state: {
        title: item.title,
        text: item.metadata.text,
        image: item.metadata.image.url,
        date: formatDate(item.metadata.date),
      }
    })
  }

  return data !== null && data !== undefined ? (
    <div className="view-container">
      <PagesBanner title={title} />
      <Container className="news-container">
        {
          data?.map((item, index) => {
            return (<Card key={index} className="news-card" onClick={() => goToNewsPage(item, index)}>
              {item.metadata.image.url && <Card.Img variant="top" src={item.metadata.image.url} className="news-card-image" />}
              <Card.Body className="news-card-body">
                <Card.Title className="news-card-title">{item.title}</Card.Title>
                <Card.Text className={`news-card-text ${!item.metadata.image.url && 'news-card-text-no-image'}`}>
                  {item.metadata.text}
                </Card.Text>
                <Link to={{
                  pathname: `/noticias/${index}`,
                  state: {
                    title: item.title,
                    text: item.metadata.text,
                    image: item.metadata.image.url,
                    date: formatDate(item.metadata.date),
                  }
                }} className="news-card-link">Leia Mais +</Link>
              </Card.Body>
            </Card>)
          })
        }
      </Container>
    </div >
  ) : <Spinner animation="border" className="global-spinner" />;
}

export default News;
