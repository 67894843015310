import "./FeedbackCarousel.css";
import { useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import quoteIcon from "../../images/quote_icon.png";

function FeedbackCarousel(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      variant="dark"
      className="carousel-container"
      activeIndex={index}
      onSelect={handleSelect}
      prevLabel={null}
      nextLabel={null}
      prevIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon carousel-container-prev-icon"
        />
      }
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-next-icon carousel-container-next-icon"
        />
      }
    >
      {props.feedbacks.map((feedback, index) => {
        return (
          <Carousel.Item key={index}>
            <Container className="quote-row">
              <img
                alt="Icone de Abre Aspas"
                src={quoteIcon}
                className="quote-icon"
              />
              <Container className="quote-content">
                <p className="quote-text">{feedback.feedback_text}</p>
                <p className="quote-author">{feedback.feedback_author}</p>
              </Container>
            </Container>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default FeedbackCarousel;
